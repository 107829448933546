import React from 'react';

function AnnouncementBanner() {
  return (
    <div style={{ backgroundColor: '#4cd5c5', marginTop: '80px', padding: '10px', textAlign: 'center'}}>
      <p>Early access to v1.2.6 is now available!</p>
    </div>
  );
}

export default AnnouncementBanner;