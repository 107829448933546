import axios from "axios";

import { BETA_SIGNUP_ENDPOINT } from "../store/api_consts";

export async function postBetaSignUp(email, selectedCountry, selectedRegion) {
    let context = {
        email : email,
        country : selectedCountry,
        region : selectedRegion
    }
    let response = await axios.post(BETA_SIGNUP_ENDPOINT, context)
    return response
}