import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../../../components/Elements/BlogBox";
import { useNavigate } from "react-router-dom";
import {ArticleMeta03} from "../../../articles/article03";
import {ArticleMeta04} from "../../../articles/article04";
import {ArticleMeta05} from "../../../articles/article05";

export default function BlogSection() {
  const navigate = useNavigate();

  const articles = [{title:ArticleMeta03.title, text:ArticleMeta03.desc, tag:"general audience", author:"CrowdHire Staff", action:() => navigate(`/blog`+ArticleMeta03.url)},
  {title:ArticleMeta04.title, text:ArticleMeta04.desc, tag:"general audience", author:"CrowdHire Staff", action:() => navigate(`/blog`+ArticleMeta04.url)},
  {title:ArticleMeta05.title, text:ArticleMeta05.desc, tag:"general audience", author:"CrowdHire Staff", action:() => navigate(`/blog`+ArticleMeta05.url)},]

  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Recent Blog Stories</h1>
         {/* <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p> */}
          </HeaderInfo>
          {articles.map((article, index) => (
            index % 3 === 0 ? (
              // Start a new row for every third article
              <div key={index} className="row textCenter">
                {/* Render the first BlogBox */}
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <BlogBox
                    title={article.title}
                    text={article.text}
                    tag={article.tag}
                    author={article.author}
                    action={article.action}
                  />
                </div>
                {/* Render the second BlogBox */}
                {index + 1 < articles.length && (
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <BlogBox
                      title={articles[index + 1].title}
                      text={articles[index + 1].text}
                      tag={articles[index + 1].tag}
                      author={articles[index + 1].author}
                      action={articles[index + 1].action}
                    />
                  </div>
                )}
                {/* Render the third BlogBox */}
                {index + 2 < articles.length && (
                  <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <BlogBox
                      title={articles[index + 2].title}
                      text={articles[index + 2].text}
                      tag={articles[index + 2].tag}
                      author={articles[index + 2].author}
                      action={articles[index + 2].action}
                    />
                  </div>
                )}
              </div>
            ) : null
          ))}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 40px;
  padding-bottom: 50px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;