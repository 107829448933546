import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 50 50" {...props}>
      <defs>
        {/* Inline styles for defining circle colors */}
        <style>
          {`
            .cls-1 {
              fill: #f2b300; /* Yellow color */
            }

            .cls-2 {
              fill: #7620ff; /* Purple color */
            }

            .cls-3 {
              fill: #4cd5c5; /* Cyan color */
            }
          `}
        </style>
      </defs>
     
      <circle className="cls-2" cx="39" cy="38.5" r="10"/>
      <circle className="cls-3" cx="16" cy="24.5" r="10"/>
      <circle className="cls-1" cx="39.5" cy="10" r="10"/>
     
    </svg>
  );
}

export default SvgComponent;
