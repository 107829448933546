import React, { useState } from "react";
import styled from "styled-components";
// Assets
import CountrySelector from "../CountrySelector";
import FullButton from "../Buttons/FullButton";

import { postBetaSignUp } from "../../api/betaSignUp";


export default function Subscribe() {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [email, setEmail] = useState('');

  const handleCountryChange = (val) => {
    setSelectedCountry(val);
    setSelectedRegion('');
  };

  const handleRegionChange = (val) => {
    setSelectedRegion(val);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value); // Update the email state with the input value
  };

  const handleBetaSignUp = () => {
    if (!email) {
      alert('Please enter an Email');
    } else if (!selectedCountry) {
      alert('Please select a Country');
    } else if (!selectedRegion) {
      alert('Please select a Region');
    } else {
      postBetaSignUp(email, selectedCountry, selectedRegion)
      .then(
        value => {
          if (value.data == 200) {
            setSelectedCountry('')
            setSelectedRegion('')
            setEmail('')
            alert('Thank you for signing up for our Beta!')
          } else {
            alert('An error occurred during the sign-up process, please try again later')
          }
        }
      )
    }
  };

  return (
    <Wrapper id="contact">
      <div className="orangeBg">
        <div className="container">
          <div className="row" style={{ paddingBottom: "70px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div className="flexNullCenter flexColumn">
                <div style={{ marginTop: "100px" }}>
                  <h1 className="font40 extraBold">Sign up for the latest news</h1>
                <p className="font13">
                  Keep updated with the latest CrowdHire news.
                </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form>
                <label className="font13">Email:</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className="font20 extraBold"
                    value={email} // Bind the input value to the email state
                    onChange={handleEmailChange} // Handle changes to update the email state
                  />
                <label className="font13">Country:</label>
                  <CountrySelector
                    country={selectedCountry}
                    region={selectedRegion}
                    onCountryChange={handleCountryChange}
                    onRegionChange={handleRegionChange}
                  />
              </Form>
              <SumbitWrapper className="flex">
                <FullButton title="Subscribe" action={handleBetaSignUp}></FullButton>
              </SumbitWrapper>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









