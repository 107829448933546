import React, { useEffect } from 'react';
import styled from 'styled-components';
import BlogBox from '../../components/Elements/BlogBox';
import { useNavigate } from "react-router-dom";
import { ArticleMeta01 } from '../../articles/article01';
import { ArticleMeta02 } from '../../articles/article02';
import { ArticleMeta03 } from '../../articles/article03';
import { ArticleMeta04 } from '../../articles/article04';
import { ArticleMeta05 } from '../../articles/article05';



export default function BlogIndex(){
  const navigate = useNavigate();
  const articles = [
    {title:ArticleMeta01.title, text:ArticleMeta01.desc, tag:"researcher", author:"CrowdHire Staff", action:() => navigate(`/blog`+ArticleMeta01.url)},
    {title:ArticleMeta02.title, text:ArticleMeta02.desc, tag:"researcher", author:"CrowdHire Staff", action:() => navigate(`/blog`+ArticleMeta02.url)},
    {title:ArticleMeta03.title, text:ArticleMeta03.desc, tag:"general audience", author:"CrowdHire Staff", action:() => navigate(`/blog`+ArticleMeta03.url)},
    {title:ArticleMeta04.title, text:ArticleMeta04.desc, tag:"general audience", author:"CrowdHire Staff", action:() => navigate(`/blog`+ArticleMeta04.url)},
    {title:ArticleMeta05.title, text:ArticleMeta05.desc, tag:"general audience", author:"CrowdHire Staff", action:() => navigate(`/blog`+ArticleMeta05.url)},

  ]

    return (
  <>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="description" content="We connect people from all walks of life with renowned academic institutions, innovative companies, and AI developers to participate in meaningful research studies."/>
    <title>CrowdHire Blog - Innovation Crowd Sourced Market Research</title>

    <Wrapper id="services">
      <div className="whiteBg" >
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Welcome to the Crowdhire Blog,</h1>
            <p className="font13">Crowdhire is a platform that connects people from all walks of life with renowned academic institutions, innovative companies, and AI developers to participate in meaningful research studies.
            Below, you'll find a curated list of articles covering a range of topics that showcase the exciting opportunities available through Crowdhire. Explore, learn, and discover how you can be part of something bigger with us.
            </p>
          </HeaderInfo>
        </div>
      </div>
      <div className="whiteBg" style={{marginTop:"40px"}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Blog Stories</h1>
          </HeaderInfo>
          {articles.map((article, index) => (
  index % 3 === 0 ? (
    // Start a new row for every third article
    <div key={index} className="row textCenter">
      {/* Render the first BlogBox */}
      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
        <BlogBox
          title={article.title}
          text={article.text}
          tag={article.tag}
          author={article.author}
          action={article.action}
        />
      </div>
      {/* Render the second BlogBox */}
      {index + 1 < articles.length && (
        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
          <BlogBox
            title={articles[index + 1].title}
            text={articles[index + 1].text}
            tag={articles[index + 1].tag}
            author={articles[index + 1].author}
            action={articles[index + 1].action}
          />
        </div>
      )}
      {/* Render the third BlogBox */}
      {index + 2 < articles.length && (
        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
          <BlogBox
            title={articles[index + 2].title}
            text={articles[index + 2].text}
            tag={articles[index + 2].tag}
            author={articles[index + 2].author}
            action={articles[index + 2].action}
          />
        </div>
      )}
    </div>
  ) : null
))}
         </div>
        </div>
    </Wrapper>
  </>
);
}

const Wrapper = styled.section`
  width: 100%;
  margin-bottom: 50px;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;