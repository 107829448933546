import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import './style/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";
import { HashRouter, useLocation } from 'react-router-dom';
import { GA_MEASUREMENT_ID } from './store/env_consts';

const ScrollWrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

ReactGA.initialize(GA_MEASUREMENT_ID);
ReactDOM.render(
  <>
  <React.StrictMode>
    <HashRouter>
      <ScrollWrapper>
        <App />
      </ScrollWrapper>
    </HashRouter>
  </React.StrictMode>
  </>,
  document.getElementById('root')
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
reportWebVitals(SendAnalytics);
