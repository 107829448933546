import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Landing from '../flows/Landing.jsx';
import Landing2 from '../flows/Landing_2.js/index.js';
import BlogRouter from '../flows/blog/blogRouter.js';

const Router = () => {
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    return (
        <Routes>
            <Route path= "/" element= {<Landing />} key="home"/>
            <Route path= "/gig-with-crowdhire" element={<Landing2 />} key="gig-with-crowdhire" /> 
            <Route path= "/blog/*" element={<BlogRouter />} key="blog"/> 
        </Routes>
    )
}

export default Router;