import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";
import GDPRBadge from "../../assets/svg/gdpr.svg";
import { Row } from "reactstrap";



export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
            <div className="row flexCenter" style={{ padding: "50px 50px" }}>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <Link className="animate pointer" to="home" smooth={true} offset={-80}>
                  <div style={{ marginLeft: "10px" }}>
                    <div className="row textCenter">
                      <LogoImg />
                      <h1 className="font15 extraBold whiteColor" style={{ margin: "15px", marginLeft: "10px" }}>
                        CrowdHire
                      </h1>
                    </div>
                  </div>
                </Link>
                <div>
                  <img src={GDPRBadge} alt="GDPR Badge" />
                </div>
              </div>

              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <StyleP className="whiteColor font13">
                  © {getCurrentYear()} - <span className="purpleColor font13">CrowdHire Technologies LLC.</span> All Right Reserved
                </StyleP>
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
              </div>
              <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2">
                <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
                  Back to top
                </Link>
              </div>
            </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;