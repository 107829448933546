import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import BlogIndex from '.';

import TopNavbarChild from '../../components/Nav/TopNavbarChild';
import Footer from "../../components/Sections/Footer"
import { Container } from 'reactstrap';

import {Article01, ArticleMeta01} from "../../articles/article01";
import {Article02, ArticleMeta02} from "../../articles/article02";
import {Article03, ArticleMeta03} from "../../articles/article03";
import {Article04, ArticleMeta04} from "../../articles/article04";
import {Article05, ArticleMeta05} from "../../articles/article05";


import ArticleTemplate from "./components/ArticleTemplate";

const BlogRouter = () => (
  <>
    <TopNavbarChild />
        <br></br>
        <br></br>
        <Container>
        <div style={{ padding: "30px 0" }}>
    
        </div>
      
        <Routes>
            <Route path={`/overview`} element={<BlogIndex/>} />
            <Route path={ArticleMeta01.url} element={<ArticleTemplate meta={ArticleMeta01} article={Article01}/>} />
            <Route path={ArticleMeta02.url} element={<ArticleTemplate meta={ArticleMeta02} article={Article02}/>} />
            <Route path={ArticleMeta03.url} element={<ArticleTemplate meta={ArticleMeta03} article={Article03}/>} />
            <Route path={ArticleMeta04.url} element={<ArticleTemplate meta={ArticleMeta04} article={Article04}/>} />
            <Route path={ArticleMeta05.url} element={<ArticleTemplate meta={ArticleMeta05} article={Article05}/>} />

        </Routes>
        
        </Container>
    <Footer/>
  </>
);

export default BlogRouter;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;