import React from "react";
import { Helmet } from "react-helmet";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Footer from "../components/Sections/Footer"
import Promotion from "../components/Sections/Promotion";
import Subscribe from "../components/Sections/Subscribe";
import AnnouncementBanner from "../components/Sections/Announcement";

export default function Landing() {
  return (
    <>
      <Helmet>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content="We connect people from all walks of life with renowned academic institutions, innovative companies, and AI developers to participate in meaningful research studies."/>
      <title>CrowdHire - Wisdom of Crowds for Market and User Research</title>
      </Helmet>
      <TopNavbar />
      <AnnouncementBanner />
      <Header />
      <Promotion />
      <Services />
      <Subscribe />
      <Footer />
    </>
  );
}


