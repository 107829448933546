import React from "react";
import { Helmet } from "react-helmet";
// Sections
import TopNavbarChild from "../../components/Nav/TopNavbarChild";
import Features from "./components/Features";
import LandingHero from "./components/LandingHero";
import Footer from "../../components/Sections/Footer"
import AnnouncementBanner from "../../components/Sections/Announcement";
import Testimonials from "./components/Testimonials";

export default function Landing2() {
  return (
    <>
      <Helmet>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content="User interviews are a cornerstone of gathering invaluable insights for businesses and research projects alike. Learn how conducting user interviews can provide invaluable insights for your business or research project, and how you can get started today."/>
      <title>Gig with CrowdHire - Complete Surveys Online and Earn Rewards</title>
      </Helmet>

      <TopNavbarChild />
      <LandingHero />
      <Features />
      <Testimonials />
      <Footer />
    </>
  );
}


