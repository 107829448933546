import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import ClientLogo00 from "../../assets/img/clients/logo00.png"
import ClientLogo01 from "../../assets/img/clients/logo01.png"
import ClientLogo02 from "../../assets/img/clients/logo02.png";
import ClientLogo03 from "../../assets/img/clients/logo03.png";
import ClientLogo04 from "../../assets/img/clients/logo04.png";
import ClientLogo05 from "../../assets/img/clients/logo05.svg";
import ClientLogo06 from "../../assets/img/clients/logo06.svg";

export default function ClientSlider() {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>

      <Slider {...settings}>
  
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo01} alt="client logo" />
        </LogoWrapper>

        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo02} alt="client logo" />
        </LogoWrapper>

        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo03} alt="client logo" />
        </LogoWrapper>
 
        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo04} alt="client logo" />
        </LogoWrapper>

        <LogoWrapper className="flexCenter">
          <ImgStyle src={ClientLogo00} alt="client logo" />
        </LogoWrapper>
      </Slider>
  
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 150px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
  @media (max-width: 1024px) {
    height: 100px;
  }
  @media (max-width: 480px) {
    height: 120px;
  }
`;


const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;