import React from "react"
import { Helmet } from "react-helmet"
import BlogSection from "./BlogSection"

export default function ArticleTemplate({meta, article}) {
  return (
   <>
    <Helmet>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={meta.desc}/>
      <meta name="keywords" content={meta.keywords}/>

      <title>{meta.title}</title>
    </Helmet>

    {article}

    <BlogSection></BlogSection>    
  </>
)}