
import React from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

class CountrySelector extends React.Component {
  render() {
    const {
      country,
      region,
      onCountryChange,
      onRegionChange,
    } = this.props;

    return (
      <div>
        <CountryDropdown
          value={country}
          onChange={(val) => onCountryChange(val)}
        />
        <RegionDropdown
          country={country}
          value={region}
          onChange={(val) => onRegionChange(val)}
        />
      </div>
    );
  }
}

export default CountrySelector;
